import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Gallery from '../MenProject/Gallery'
import Footer from '../Footer/Main'

function Main() {
  return (
    <>
    <Header/>
    <Breadcrumb mainheading={"Case Study"} sub={"Home"} title={"Portfolio"}/>
    <Gallery/>
    <Footer/>
    </>
  )
}

export default Main