import React, { useState } from 'react'
import bg from '../../assets/img/bg/insights-img01.png'
import icon from '../../assets/img/icon/insights-icon01.png'
import icon01 from '../../assets/img/icon/insights-icon02.png'
import bg01 from '../../assets/img/bg/insights-img02.png'
import bg02 from '../../assets/img/bg/insights-video.png'
import { Link } from 'react-router-dom'

function Insightarea() {

    const [video, setVideo] = useState(false);

    return (
        <>
            <section className="insights-area pt-15 pb-15 p-relative fix">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <div className="img">
                                        <img src={bg} alt="img" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <div className="insights-text">
                                        {/*<div className="insight-icon">
                                            <img src={icon} alt="img" />
    </div>*/}
                                        <div className="insight-ptext">
                                            <h3>ICT Solutions</h3>
                                            <p>Tailored digital solutions to enhance operational efficiency.
</p>
                                            <p>Comprehensive ICT strategies for business optimization.
</p>
<p>Integration of cutting-edge technologies for seamless workflow</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <div className="insights-text colr2">
                                        {/*<div className="insight-icon">
                                            <img src={icon01} alt="img" />
</div>*/}
                                        <div className="insight-ptext">
                                            <h3>Website Design and Development</h3>
                                            
                                            <p>Customized website development tailored to client specifications.
</p>
                                            <p>User-centric design for intuitive and engaging user experiences.</p>
                                            <p>Responsive and scalable websites optimized for various devices and platforms.
</p>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 col-md-6 mb-30">
                                    <div className="img">
                                        <img src={bg01} alt="img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 mb-30">
                                    <div className="insights-text">
                                        {/*<div className="insight-icon">
                                            <img src={icon} alt="img" />
    </div>*/}
                                        <div className="insight-ptext">
                                            <h3>Web Application Development</h3>
                                            <p>Development of dynamic web applications to meet specific business needs.

</p>
                                            <p>Seamless integration of functionality and user interface for enhanced usability.
</p>
<p>Cross-platform compatibility for accessibility across different browsers and devices.</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                        <div className="section-title center-align mt-80 mb-80 text-center wow fadeInDown animated">
    <h1 style={{ paddingTop: "280px" }}>Midklick's focus is to build modern and innovative software solutions for small, medium and large businesses as well as trust and empower Omani developers.</h1>
</div>
                        </div>
                    </div>
                </div>
            </section>

            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex={-1} style={{ overflow: "hidden auto" }} >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)}>
                                        ×
                                    </button>
                                    <iframe
                                        className="mfp-iframe"
                                        src="https://www.youtube.com/embed/6mpDNxI5gFY?autoplay=1"
                                        frameBorder={0}
                                        allowFullScreen=""
                                    />
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default Insightarea