import React from 'react'
import { Link } from 'react-router-dom'
import gallery01 from '../../assets/img/gallery/protfolio-img01.jpg'
import gallery02 from '../../assets/img/gallery/protfolio-img02.jpg'
import gallery03 from '../../assets/img/gallery/protfolio-img03.jpg'
import gallery04 from '../../assets/img/gallery/protfolio-img04.png'
import gallery05 from '../../assets/img/gallery/protfolio-img05.png'
import gallery06 from '../../assets/img/gallery/protfolio-img06.png'
import gallery07 from '../../assets/img/gallery/protfolio-img07.png'
import gallery08 from '../../assets/img/gallery/protfolio-img08.png'
import gallery09 from '../../assets/img/gallery/protfolio-img09.png'
import gallery10 from '../../assets/img/gallery/protfolio-img10.png'

function Gallery() {
    return (
        <>
            <section id="portfolio" className="portfolio2 pt-120 pb-110 fix" style={{ background: "#fff" }} >
                <div className="container">
                    <div className="portfolio ">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery01} alt="img" className="img" />
                                        <figcaption>
                                            <span>Design, App</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                Alex Neural AI App Design
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery02} alt="img" className="img" />
                                        <figcaption>
                                            <span>Website, Dashboard</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                iPhone Website Design
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery03} alt="img" className="img" />
                                        <figcaption>
                                            <span>UI, UX, iOs</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                Call Of Duty Mobile
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery04} alt="img" className="img" />
                                        <figcaption>
                                            <span>Design, App</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                Alex Neural AI App Design
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery05} alt="img" className="img" />
                                        <figcaption>
                                            <span>Website, Dashboard</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                iPhone Website Design
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery06} alt="img" className="img" />
                                        <figcaption>
                                            <span>UI, UX, iOs</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                Call Of Duty Mobile
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery07} alt="img" className="img" />
                                        <figcaption>
                                            <span>Website, Dashboard</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                iPhone Website Design
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery08} alt="img" className="img" />
                                        <figcaption>
                                            <span>UI, UX, iOs</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                Call Of Duty Mobile
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery09} alt="img" className="img" />
                                        <figcaption>
                                            <span>Website, Dashboard</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                iPhone Website Design
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Link to="/project-details">
                                    <figure className="gallery-image">
                                        <img src={gallery10} alt="img" className="img" />
                                        <figcaption>
                                            <span>UI, UX, iOs</span>
                                            <h4>
                                                <i className="fas fa-long-arrow-right" />
                                                Call Of Duty Mobile
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                        </div>
                        <div className="row align-items-center mt-50">
                            <div className="col-lg-12 col-md-12 text-center">
                                <div className="slider-btn ">
                                    <Link to="/contact" className="btn ss-btn active" data-animation="fadeInUp" data-delay=".4s" >
                                        <i className="fas fa-angle-right" /> more service
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery