import React from 'react'
import bg1 from '../../assets/img/bg/contact-img.png'
import bg2 from '../../assets/img/icon/team-single-icon-01.png'
import bg3 from '../../assets/img/icon/team-single-icon-02.png'
import bg4 from '../../assets/img/icon/team-single-icon-03.png'
import { Link } from 'react-router-dom'

function Teamcontact() {
    return (
        <>
            <section className="team-single-area fix p-relative pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-6 col-md-6">
                            <div className="single-team-singel mb-40 p-relative">
                                <div className="team-thumb">
                                    <img src={bg1} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <div className="single-team-content-single">
                                <div className="section-title p-relative mb-50 wow fadeInLeft  animated" data-animation="fadeInLeft" data-delay=".4s" >
                                    <h2>Contact Us Now</h2>
                                    <p>
                                    Midklick: Pioneering modern solutions for businesses, empowering Omani developers. Contact us today to unlock your potential.
                                    </p>
                                </div>
                                <div className="team-single-info">
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <img src={bg2} alt="img" />
                                            </div>
                                            <div className="text">
                                                <p>Business Email</p>
                                                <h3>
                                                    <Link to="mailto:info@midklick.com">info@midklick.com</Link>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src={bg3} alt="img" />
                                            </div>
                                            <div className="text">
                                                <p>Phone Number</p>
                                                <h3>
                                                    <Link to="tel:+968 9144 1064">+968 9144 1064</Link>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src={bg4} alt="img" />
                                            </div>
                                            <div className="text">
                                                <p>Office Address</p>
                                                <h3>Ghala, Muscat, Sultanat of Oman</h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Teamcontact